import React from 'react';
import SlickCarousel from 'react-slick';

import styles from './TestimonyCardCarousel.module.scss';
import Carousel from '../../../components/Carousel';
import Button from '../../../components/Button';
import Icon from '../../../components/icons/Icon';
import Flex from '../../../components/layout/Flex';

type Props = React.PropsWithChildren<{
  onActiveSlideChange(slideNumber: number): void;
}>;

const TestimonyCardCarousel = (props: Props) => {
  const carouselRef = React.createRef<SlickCarousel>();

  function onLeftArrowClick() {
    carouselRef.current?.slickPrev();
  }

  function onRightArrowClick() {
    carouselRef.current?.slickNext();
  }

  return (
    <div>
      <Carousel
        ref={carouselRef}
        infinite
        arrows={false}
        centerMode
        centerPadding="94px"
        slidesToShow={3}
        speed={350}
        touchThreshold={15}
        responsive={[
          {
            breakpoint: 2561,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 1700,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 1,
              centerPadding: '350px',
            },
          },
          {
            breakpoint: 1026,
            settings: {
              slidesToShow: 1,
              centerPadding: '270px',
            },
          },
          {
            breakpoint: 850,
            settings: {
              slidesToShow: 1,
              centerPadding: '200px',
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              centerPadding: '100px',
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 1,
              centerPadding: '60px',
            },
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1,
              centerPadding: '45px',
            },
          },
        ]}
        beforeChange={(currSlide, nextSlide) => props.onActiveSlideChange(nextSlide)}
      >
        {props.children}
      </Carousel>

      <Flex className={styles.btnContainer} alignItems="center" justifyContent="center">
        <Button
          className={styles.btnLeft}
          color="transparent"
          padding="light"
          onClick={onLeftArrowClick}
          aria-label="Previous card"
        >
          <Icon name="arrowRight" height="4rem" />
        </Button>
        <Button
          className={styles.btnRight}
          color="transparent"
          padding="light"
          onClick={onRightArrowClick}
          aria-label="Next card"
        >
          <Icon name="arrowRight" height="4rem" />
        </Button>
      </Flex>
    </div>
  );
};

export default TestimonyCardCarousel;
