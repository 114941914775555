import React from 'react';
import classNames from 'classnames';

import styles from './TestimonyCard.module.scss';
import QuoteggtionMarks from '../media/quoteggtionMarks.svg';
import QuoteggtionMarksOrange from '../media/quoteggtionMarksOrange.svg';

type Props = {
  quote: React.ReactNode;
  author: string;
  organization: string;
  active?: boolean;
  color: 'purple' | 'orange';
};

const TestimonyCard = (props: Props) => (
  <div className={styles.cardWrapper}>
    <div
      className={classNames({
        [styles.card]: props.active,
        [styles.cardInactive]: !props.active,
        [styles.purpleBg]: props.color === 'purple',
        [styles.orangeBg]: props.color === 'orange',
      })}
    >
      <div>
        {props.color === 'purple' ? <QuoteggtionMarksOrange width="4.3rem" /> : <QuoteggtionMarks width="4.3rem" />}
        <div className={styles.quote}>"{props.quote}"</div>
      </div>
      <div>
        <div className={styles.author}>{props.author}</div>
        <div className={styles.organization}>{props.organization}</div>
      </div>
    </div>
  </div>
);

export default TestimonyCard;
