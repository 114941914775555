import React, { ReactNode } from 'react';

import Link from '../components/Link';
import routes from '../routes';

// A common place for providing FAQ content on pricing pages, and as structured schema (schema.org).
// Original plan was to use ReactDOMServer to output static markup from answer JSX to use in the structured schema JSON,
// but this turned out to be not possible with the Link component due to ReactRouter ¯\_(ツ)_/¯. So my next best
// idea is to at least put the JSX and static markup here in the same place so if/when they change, it will
// hopefully be clear to change both JSX and static markup

interface QuestionAndAnswer {
  q: string;
  a: ((tabIndex: 0 | -1) => ReactNode) | string;
  aStringMarkup?: string;
}

export const pricingReqQuoteFormId = 'pricingReqQuoteForm';

const pricingContactStaticLink = `<a href=https://www.goosechase.com${routes.PRICING.path}#${pricingReqQuoteFormId}>contact us</a>`;

export const pricingFAQ: { licenses: QuestionAndAnswer[]; experiences: QuestionAndAnswer[] } = {
  licenses: [
    {
      q: 'Are these prices for a single Goosechase Experience or annual subscription?',
      a: (tabIndex = 0) => (
        <>
          The Starter and Professional prices are for single-use Experiences. Please{' '}
          <Link href={`#${pricingReqQuoteFormId}`} variant="underline" tabIndex={tabIndex}>
            contact us
          </Link>{' '}
          to inquire about annual subscriptions.
        </>
      ),
      aStringMarkup: `The Starter and Professional prices are for single-use Experiences. Please ${pricingContactStaticLink} to inquire about annual subscriptions.`,
    },
    {
      q: 'Do you offer yearly subscriptions with multiple or unlimited Experiences?',
      a: (tabIndex = 0) => (
        <>
          Yes, we do! Please{' '}
          <Link href={`#${pricingReqQuoteFormId}`} variant="underline" tabIndex={tabIndex}>
            contact us
          </Link>{' '}
          to inquire about our annual subscription option.
        </>
      ),
      aStringMarkup: `Yes, we do! Please ${pricingContactStaticLink} to inquire about our annual subscription option.`,
    },
    {
      q: "What's the difference between the free version and the paid version?",
      a: (tabIndex = 0) => (
        <>
          The only difference is the number of participants allowed per Experience. Other than that, you will have
          access to all of our features regardless of the license you have. Please{' '}
          <Link href={`#${pricingReqQuoteFormId}`} variant="underline" tabIndex={tabIndex}>
            contact us
          </Link>{' '}
          if you require more than 20 teams or individual participants.
        </>
      ),
      aStringMarkup: `The only difference is the number of participants allowed per Experience. Other than that, you will have access to all of our features regardless of the license you have. Please ${pricingContactStaticLink} if you require more than 20 teams or individual participants.`,
    },
    {
      q: 'How big can my Experience be?',
      a: (tabIndex = 0) => (
        <>
          Our platform is built to scale! You can have as many participants as you require in your Experience. Please{' '}
          <Link href={`#${pricingReqQuoteFormId}`} variant="underline" tabIndex={tabIndex}>
            contact us
          </Link>{' '}
          if you need more participants than the amounts listed on our{' '}
          <Link href={routes.PRICING.path} variant="underline" tabIndex={tabIndex}>
            pricing page
          </Link>{' '}
          .
        </>
      ),
      aStringMarkup: `Our platform is built to scale! You can have as many participants as you require in your Experience. Please ${pricingContactStaticLink} if you need more participants than the amounts listed on our <a href=https://www.goosechase.com${routes.PRICING.path}>pricing page</a>.`,
    },
    {
      q: 'Is Goosechase free?',
      a: 'Goosechase has a variety of pricing and subscription options for those looking to host one-off Experiences or create ongoing programming. And yes, the Goosechase Recreational account (which lets up to 3 solo participants or up to 3 teams join) is free.',
    },
  ],
  experiences: [
    {
      q: 'How long can my Experience last?',
      a: (tabIndex = 0) => (
        <>
          It's up to you! We recommend considering your event goals - longer is not always better! Please look at{' '}
          <Link
            variant="underline"
            href="https://blog.goosechase.com/how-long-should-a-scavenger-hunt-be/"
            target="_blank"
            tabIndex={tabIndex}
          >
            this blog post
          </Link>{' '}
          describing the different Experience lengths to determine which is the best fit for you. If you have additional
          questions, please reach out to us through our website's chat box or email us at{' '}
          <Link variant="underline" href="mailto:hi@goosechase.com" tabIndex={tabIndex}>
            hi@goosechase.com
          </Link>
          .
        </>
      ),
      aStringMarkup: `It's up to you! We recommend considering your event goals - longer is not always better! Please look at <a href=https://blog.goosechase.com/how-long-should-a-scavenger-hunt-be/>this blog post</a> describing the different Experience lengths to determine which is the best fit for you. If you have additional questions, please reach out to us through our website's chat box or email us at <a href=mailto:hi@goosechase.com>hi@goosechase.com</a>.`,
    },
    {
      q: "What's the difference between a Goosechase Experience, Experience, and a Mission?",
      a: 'A Goosechase Experience is created by you and joined by participants. It may also be referred to as an Experience. Missions are the tasks within the Experience that participants complete to earn points.',
    },
    {
      q: 'How do participants join my Experience?',
      a: (tabIndex = 0) => (
        <>
          Experiences can only be played using the{' '}
          <Link variant="underline" href={routes.DOWNLOAD.path} useRouter tabIndex={tabIndex}>
            Goosechase App
          </Link>{' '}
          on an iOS or Android device. They can choose to play as a guest or create an account and quickly join your
          Experience with a unique join code. Full details on how to join an Experience can be found{' '}
          <Link
            variant="underline"
            href="https://support.goosechase.com/en/articles/4437537-how-do-students-staff-join-my-game"
            target="_blank"
            tabIndex={tabIndex}
          >
            here
          </Link>{' '}
          .
        </>
      ),
      aStringMarkup: `Experiences can only be played using the <a href=https://www.goosechase.com${routes.DOWNLOAD.path}>Goosechase App</a> on an iOS or Android device. They can choose to play as a guest or create an account and quickly join your Experience with a unique join code. Full details on how to join an Experience can be found <a href=https://support.goosechase.com/en/articles/4437537-how-do-students-staff-join-my-game>here</a>.`,
    },
    {
      q: 'Can you help me create Missions and run my Experience?',
      a: (tabIndex = 0) => (
        <>
          Unfortunately, we do not create your Experience for you. That doesn't mean you're not supported! We have tons
          of resources available that will help you through each step of the way. Take a look at{' '}
          <Link
            variant="underline"
            href="https://support.goosechase.com/en/articles/4437524-getting-started-with-goosechase"
            target="_blank"
            tabIndex={tabIndex}
          >
            Getting Started with Goosechase
          </Link>{' '}
          for a step-by-step guide for creating your Experience. Or visit the{' '}
          <Link
            variant="underline"
            href="https://support.goosechase.com/en/collections/2545171-frequently-asked-questions"
            target="_blank"
            tabIndex={tabIndex}
          >
            FAQ
          </Link>{' '}
          for additional answers to common questions!
        </>
      ),
      aStringMarkup: `Unfortunately, we do not create your Experience for you. That doesn't mean you're not supported! We have tons of resources available that will help you through each step of the way. Take a look at <a href=https://support.goosechase.com/en/articles/4437524-getting-started-with-goosechase>Getting Started with Goosechase</a> for a step-by-step guide for creating your Experience. Or visit the <a href=https://support.goosechase.com/en/collections/2545171-frequently-asked-questions>FAQ</a> for additional answers to common questions!`,
    },
    {
      q: 'How long does it take to create a Goosechase Experience?',
      a: 'Tricky question! Creating your Experience depends on what you are trying to accomplish. We have seen smaller games built within 20 minutes and larger, long-term Experiences that take up to a few weeks to plan and organize - it all depends on your event!',
    },
  ],
};

export const eduPricingFAQ: { licenses: QuestionAndAnswer[]; experiences: QuestionAndAnswer[] } = {
  licenses: [
    {
      q: 'Can I create a Goosechase Experience and re-use it for multiple class sections?',
      a: (tabIndex = 0) => (
        <>
          Absolutely! By creating a single Goosechase Experience and then duplicating it for each class section, you can
          run identical Experiences for multiple groups of students. All submissions and results stay separate for each
          Experience.{' '}
          <Link
            variant="underline"
            href="https://support.goosechase.com/en/articles/4437540-using-goosechase-edu-with-multiple-class-sections"
            target="_blank"
            tabIndex={tabIndex}
          >
            Read more
          </Link>
        </>
      ),
      aStringMarkup:
        'Absolutely! By creating a single Goosechase Experience and then duplicating it for each class section, you can run identical Experiences for multiple groups of students. All submissions and results stay separate for each Experience. <a href=https://support.goosechase.com/en/articles/4437540-using-goosechase-edu-with-multiple-class-sections>Read more</a>',
    },
    {
      q: 'Can I share my Experiences with other teachers?',
      a: (tabIndex = 0) => (
        <>
          Experiences can be easily duplicated and shared with other teachers with a unique link. You can take a look at
          the{' '}
          <Link
            variant="underline"
            href="https://www.goosechase.com/experience-library"
            target="_blank"
            tabIndex={tabIndex}
          >
            Experience Library
          </Link>{' '}
          to see examples of Experiences already created by our incredible community of educators!{' '}
          <Link
            variant="underline"
            href="https://support.goosechase.com/en/articles/4437563-how-do-i-share-a-copy-of-my-experience-with-another-creator"
            target="_blank"
            tabIndex={tabIndex}
          >
            Read more
          </Link>
        </>
      ),
      aStringMarkup: `Goosechase Experiences can be easily duplicated and shared with other teachers with a unique link. <a href=https://support.goosechase.com/en/articles/4437563-how-do-i-copy-a-game-to-another-organizer-account>Learn how to copy an Experience</a> to another organizer account.`,
    },
    {
      q: 'Do you accept purchase orders?',
      a: 'We accept purchase orders for district & school-wide licenses! Payment for Educator Plus subscriptions must be made via credit card from within the Experience Manager.',
    },
    {
      q: 'Is Goosechase free for educators?',
      a: 'We offer special pricing plans and subscriptions designed to help provide more educators with the tools needed to encourage active learning all year round. And yes, our EDU Basic plan is always free for K-12 educators.',
    },
  ],
  experiences: [
    {
      q: 'How is Goosechase used in the classroom?',
      a: (tabIndex = 0) => (
        <>
          Teachers design interactive activities on Goosechase that ask students to show what they know by answering
          questions, submitting photos/videos, or checking in at locations. It's a fun way to explore new topics and
          review lessons!
        </>
      ),
      aStringMarkup:
        "Teachers design interactive activities on Goosechase that ask students to show what they know by answering questions, submitting photos/videos, or checking in at locations. It's a fun way to explore new topics and review lessons!",
    },
    {
      q: 'How can my students join the experience?',
      a: (tabIndex = 0) => (
        <>
          Experiences are joined through our iOS or Android app on mobile devices. The Android app is compatible with
          most Chromebook devices. Please{' '}
          <Link
            variant="underline"
            href="https://support.goosechase.com/en/articles/4437551-using-goosechase-on-chromebooks"
            target="_blank"
            tabIndex={tabIndex}
          >
            follow these steps
          </Link>{' '}
          to check if the Goosechase App is compatible with your Chromebook or Macs with the M1 chip..
        </>
      ),
      aStringMarkup:
        'Experiences are joined through our iOS or Android app on mobile devices. The Android app is compatible with most Chromebook devices. Please <a href=https://support.goosechase.com/en/articles/4437551-using-goosechase-on-chromebooks>follow these steps</a> to check if the Goosechase App is compatible with your Chromebook.',
    },
    {
      q: "What's the difference between a Goosechase Experience and a Mission?",
      a: 'A Goosechase Experience is created by you and joined by participants. It may also be referred to as an Experience. Missions are the tasks within the Experience that participants complete to earn points.',
    },
    {
      q: 'Can I monitor the Experience while it is live?',
      a: "Yes! You can view each Mission submission as participants complete them through the live activity feed. You can award bonus points for outstanding submissions, and remove points from or delete submissions that don't meet the Mission requirements.",
    },
    {
      q: "What's the difference between 'Teams' mode and 'Solo' mode?",
      a: (tabIndex = 0) => (
        <>
          All participants complete their Mission list from their device and receive points for their submissions in
          Solo mode. In Teams mode, each team member can still use their own device, but work together to complete a
          shared list of Missions to earn points as a team.{' '}
          <Link
            variant="underline"
            href="https://support.goosechase.com/en/articles/4437548-can-a-mission-be-completed-by-every-team-member"
            target="_blank"
            tabIndex={tabIndex}
          >
            Read more
          </Link>
        </>
      ),
      aStringMarkup:
        "All participants complete their Mission list from their device and receive points for their submission in an 'Individual participants' Experience. In a 'Teams' Experience, each team member can still use their own device but they work together to complete a shared list of Missions to earn collective points as a team. <a href=https://support.goosechase.com/en/articles/4437548-why-do-some-missions-appear-to-already-be-completed-when-playing-in-teams-mode>Read more</a>",
    },
  ],
};

export const subscriptionPricingFAQ: QuestionAndAnswer[] = [
  {
    q: 'Do you have pre-made Goosechase Experience templates that I can use?',
    a: (tabIndex = 0) => (
      <>
        Yes! We have an amazing library{' '}
        <Link
          href="https://blog.goosechase.com/tag/goosechase-mission-ideas/"
          variant="inlineText"
          target="_blank"
          tabIndex={tabIndex}
        >
          here
        </Link>{' '}
        full of pre-made Missions and templates that you can import into your account.
      </>
    ),
    aStringMarkup: `Yes! We have an amazing library <a href=https://blog.goosechase.com/tag/goosechase-mission-ideas>here</a> full of pre-made Missions and templates that you can import into your account.`,
  },
  {
    q: 'How do participants join my Goosechase Experience?',
    a: (tabIndex = 0) => (
      <>
        Goosechase Experiences are played using the{' '}
        <Link href={routes.DOWNLOAD.path} useRouter variant="inlineText" target="_blank" tabIndex={tabIndex}>
          Goosechase app
        </Link>{' '}
        on an iOS or Android device. Participants can choose to play as a guest or create an account and quickly join
        with a unique code. Full details on how to join an Experience can be found{' '}
        <Link
          href="https://support.goosechase.com/en/articles/4437537-how-do-participants-join-my-game"
          variant="inlineText"
          target="_blank"
          tabIndex={tabIndex}
        >
          here
        </Link>
        .
      </>
    ),
    aStringMarkup: `Goosechase Experiences are played using the <a href=https://www.goosechase.com${routes.DOWNLOAD.path}>Goosechase app</a> on an iOS or Android device. Participants can choose to play as a guest or create an account and quickly join with a unique code. Full details on how to join an Experience can be found <a href=https://support.goosechase.com/en/articles/4437537-how-do-participants-join-my-game>here</a>.`,
  },
  {
    q: 'Can I invite someone else to help create and monitor my Goosechase Experience?',
    a: (tabIndex = 0) => (
      <>
        With our Co-managing feature, you're able to invite collaborators to co-manage your Goosechase Experience from
        another account. Co-managers can edit Missions, participants, start and end time, and all other settings and
        attributes of your Experience. Full details on how to invite a co-manager to your Goosechase can be found{' '}
        <Link
          href="https://support.goosechase.com/en/articles/4437513-how-can-i-invite-another-organizer-to-co-manage-a-game"
          variant="inlineText"
          target="_blank"
          tabIndex={tabIndex}
        >
          here
        </Link>
        .
      </>
    ),
    aStringMarkup: `With our Co-managing feature, you're able to invite collaborators to co-manage your Goosechase Experience from another account. Co-managers can edit Missions, participants, start and end time, and all other settings and attributes of your Experience. Full details on how to invite a co-manager to your Goosechase can be found <a href=https://support.goosechase.com/en/articles/4437513-how-can-i-invite-another-organizer-to-co-manage-a-game>here</a>.`,
  },
];

export const eduSubscriptionPricingFAQ: QuestionAndAnswer[] = [
  {
    q: 'How is Goosechase used in the classroom?',
    a: "Teachers design interactive activities on Goosechase that ask students to show what they know by answering questions, submitting photos/videos, or checking in at locations. It's a fun way to explore new topics and review lessons!",
  },
  {
    q: 'Can participants join from a computer?',
    a: (tabIndex = 0) => (
      <>
        To participate, our iOS or Android apps must be used. However, if your students have access to Chromebooks, or
        Macs with the M1 chip, they should be able to{' '}
        <Link
          href="https://support.goosechase.com/en/articles/4437549-is-it-possible-to-participate-in-a-goosechase-game-from-a-computer-laptop"
          variant="inlineText"
          target="_blank"
          tabIndex={tabIndex}
        >
          use our apps on their desktop!
        </Link>
      </>
    ),
    aStringMarkup: `To participate, our iOS or Android apps must be used. However, if your students have access to Chromebooks, or Macs with the M1 chip, they should be able to <a href=https://support.goosechase.com/en/articles/4437549-is-it-possible-to-participate-in-a-goosechase-game-from-a-computer-laptop>use our apps on their desktop!</a>`,
  },
  {
    q: 'Are there any pre-made templates for teachers to use?',
    a: (tabIndex = 0) => (
      <>
        Of course! Our{' '}
        <Link href={routes.EXPERIENCE_LIBRARY.path} useRouter variant="inlineText" target="_blank" tabIndex={tabIndex}>
          Experience Template Library
        </Link>{' '}
        is updated with Goosechase Experiences, including hundreds of templates created by other educators like you. You
        can copy these to your account to edit, or use as is!
      </>
    ),
    aStringMarkup: `Of course! Our <a href=https://www.goosechase.com${routes.EXPERIENCE_LIBRARY.path}>Experience Template Library</a> is updated with Goosechase Experiences, including hundreds of templates created by other educators like you. You can copy these to your account to edit, or use as is!`,
  },
];
